.PageContainer {
  padding-top: 130px;
  display: flex;
  flex-flow: column;
  gap: 3rem;
  margin: 0px;
  margin-bottom: 50px;

  @media (min-width: 900px) {
    padding-top: 140px;
  }

  @media (min-width: 1200px) and (max-width: 1620px) and (max-height: 780px) {
    margin: 0 24px;
    margin-bottom: 50px;
  }
}

.ContentWrapper {
  z-index: 10;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-content: flex-start;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.SearchWrapper {
  z-index: 20;
  width: 100%;
  min-height: 10px;
}

.CardsWrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-content: flex-start;
  gap: 1.5rem;

  @media (min-width: 1024px) {
    flex-flow: row;
    flex-wrap: wrap;
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }

  & > div {
    &:first-of-type {
      width: 100%;

      @media (min-width: 1200px) {
        width: 57.5%;
      }
    }

    &:nth-of-type(2) {
      width: 100%;

      @media (min-width: 1200px) {
        width: 42.5%;
      }
    }
  }

  &.WithCampaigns {
    & > div {
      &:first-of-type {
        width: 100%;

        @media (min-width: 1024px) {
          width: calc(50% - 0.75rem);
        }

        @media (min-width: 1200px) {
          width: 37%;
        }
      }

      &:nth-of-type(2) {
        width: 100%;

        @media (min-width: 1024px) {
          width: calc(50% - 0.75rem);
        }

        @media (min-width: 1200px) {
          max-width: 100%;
          width: 37%;
        }
      }

      &:nth-of-type(3) {
        width: 100%;

        @media (min-width: 768px) and (max-width: 1199px) {
          .TotalsWrapper {
            display: flex;
            flex-flow: row;

            & > div {
              width: calc(50% - 0.75rem);
            }
          }
        }

        @media (min-width: 1200px) {
          width: 26%;
        }
      }
    }
  }
}

.SmallCardsWrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.TotalsWrapper {
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) and (max-width: 1199px) {
    flex-flow: row;

    & > div {
      width: calc(50% - 0.75rem);
    }
  }

  &.WithGamification {
    & > div:first-child {
      height: 100%;
    }

    & > div:last-child {
      height: 100%;
    }

    @media (min-width: 1200px) {
      flex-flow: row;
    }
  }

  @media (min-width: 1200px) {
    & > div:first-child {
      height: 50%;
    }

    & > div:last-child {
      height: 50%;
    }
  }

  & > div > div {
    justify-content: space-between;
  }
}

.GamificationWrapper {
  width: 100%;
  height: 100%;
  display: flex;

  & [class*='TitleWrapper'] {
    margin-top: 0;
  }

  & > div > div {
    justify-content: center;
  }
}

.PageContent {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  margin: 0 auto;
}
