.Wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.Link {
  width: 100%;
  text-decoration: none;
  color: inherit;
  display: flex;
  padding-bottom: 1rem;
}

.Header {
  position: relative;
  width: 100%;
  height: 150px;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  border: 1px solid rgba(var(--gray90));
  background-color: rgba(var(--white));
}

.Logo {
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
  border: 1px solid rgba(var(--gray90));
  background-color: rgba(var(--white));
  background-position: center;
}

.Title {
  margin-bottom: 0.5rem;
}

.ContentWrapper {
  display: flex;
  flex-flow: column;
}

.DetailsWrapper {
  display: flex;
  flex-flow: column;
  gap: 0.25rem;
  height: 52px;

  & > div {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }

  & p {
    color: rgba(var(--gray800))
  }
}