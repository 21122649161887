.MainWrapper {
  margin-top:  80px;
  margin-left: 64px;
  margin-right: 64px;

  @media (max-width: 900px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  * {
    font-family: Inter, sans-serif;
  }

  @media (min-width: 1568px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }

  @media (max-width: 768px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}