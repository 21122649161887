.Wrapper {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.Title  {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.ButtonsWrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 1.5rem;
}
