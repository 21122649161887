.SuggestedRowContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.SuggestedRowHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.SuggestedRowTitle {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--colors-black);
}

.SuggestedRowContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2.5rem;
  width: 100%;

  @media (min-width: 1280px) {
    gap: 2rem;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
}

.SuggestedRowItem {
  width: 100%;

  @media (min-width: 768px) {
    max-width: calc(33% - 1.5rem);
  }

  @media (min-width: 1280px) {
    width: calc(25% - 1.5rem);
  }
}
