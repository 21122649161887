.ContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.Text {
  display: block;
  color: var(--colors-black);
  letter-spacing: 0.2px;
  font-size: 14px;
  line-height: 1rem;
}

.TextLarge {
  display: block;
  color: var(--colors-black);
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: 700;
}
