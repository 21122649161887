.EditorStyles {
  width: 100%;

  * {
    font-family: Inter, sans-serif !important;
  }

  [class^="mantine"], [class^="m_"] {
    border-color: rgba(var(--gray80));
  }
}

.EditorRoot {
  width: 100%;
  min-height: 220px;

  &:hover {
    outline: 0.25rem solid rgba(var(--gray80));
    border-color: rgba(var(--black));
  }

  &[aria-invalid="true"]:hover {
    outline: 0.25rem solid rgba(var(--alert100));
    border-color: rgba(var(--alert400));
  }

  &:focus-within {
    border-color: rgba(var(--black));
  }

  &[aria-invalid="true"], &[aria-invalid="true"]:focus {
    border-color: rgba(var(--alert400));
  }
}

.EditorContent {
  p {
    font-size: 0.875rem;

    &[data-placeholder] {
      &::before {
        color: rgba(var(--neutral300)) !important;
        line-height: 1.5rem !important;
        float: none !important;
        font-size: 0.875rem;
      }
  
      br {
        display: none;
      }
    }
  }
}