.Wrapper {
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 2px 16px 0px #0d0d0d14;
  background-color: rgba(var(--white));

  & > div {
    box-sizing: border-box;
    padding: 0 1.5rem;
  }
}

.Title {
  margin: 0;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

.Description {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.43;

  p {
    margin-bottom: 0;
  }

  a {
    color: rgba(var(--primary400));
    text-decoration-color: rgba(var(--primary400));
  }
}

.ExtraPadding {
  padding-bottom: 0.5rem;
}
