.Card {
  cursor: pointer;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid  rgba(var(--info30));
  box-shadow: none;
  transition: border 0.3s;

  &.Active {
    border: 2px solid  rgba(var(--primary400));
  }

  &:hover {
    border-color: rgba(var(--primary400));
  }
}
.Title{
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.Description {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(var(--gray600));
}
