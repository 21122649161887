.Wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  gap: 0.75rem;
  padding-bottom: 0.5rem;

  .Title {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  }

  .Description {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.Banner {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: 100px;
  max-height: 100px;
  margin-top: 0.5rem;
  border-radius: 4px;
  background-color: var(--colors-gray-60);
  overflow: hidden;

  img {
    &:first-of-type {
      height: 60px;
      margin: 1rem 0 0 1.5rem;
    }

    &:last-of-type {
      height: calc(100% - 1rem);
      margin: 1rem 1rem 0 1rem;
    }
  }
}

.LinkWrapper {
  margin-top: auto;
}
