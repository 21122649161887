.WalletAmountContainer {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 0.75rem;
  background-color: rgba(var(--white));
  border-radius: 1rem;
  gap: 0.25rem;
  font-size: 0.875rem;

  span {
    font-weight: 600;
  }
}