.Wrapper {
  padding: 1rem 1.5rem;
}

.SectionName {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: rgba(var(--gray600));
}

.ActionsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.Divider {
  border-color: rgba(var(--gray80));
  margin: 1rem 0;
  border-bottom-width: 2px;
}

.AchievementsTitle {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.NoLink {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: rgba(var(--gray400));
}
