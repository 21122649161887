.Footer {
  margin: 1rem 0 0;

  & > p {
    margin: 0;
  }

  a {
    color: rgba(var(--primary400));
    text-decoration-color: rgba(var(--primary400));
  }
}