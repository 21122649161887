.ErrorContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
}

.ErrorWrapper {
  margin-top: 64px;
}
