.Wrapper {
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
  top: 80px;
  left: 0;
  right: 0;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  padding-bottom: 60px;
  background: radial-gradient(
      87.11% 94.04% at 83.75% 31.88%,
      rgba(var(--primary), 0.1) 0%,
      rgba(var(--primary), 0) 100%
    ),
    #f8f6f2;
  transition: padding 0.4s linear, top 0.4s linear;

  @media (max-width: 899px) {
    top: 80px;
    padding-top: 28px;
    padding-bottom: 76px;
  }
}

.WrapperTall {
  height: 270px;

  @media (max-width: 899px) {
    height: 310px;
  }
}

.WrapperScrolled {
  top: calc(80px - 32px - 52px);
  padding-bottom: 22px;

  @media (max-width: 899px) {
    top: calc(80px - 32px - 28px);
    padding-bottom: 22px;
  }
}

.ContentWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding-left: 64px;
  padding-right: 64px;
  max-width: calc(1440px + 128px);
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 899px) {
    justify-content: start;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.Icon {
  display: flex;
  font-size: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  min-width: 56px;
  border-radius: 50%;
  background-color: rgb(var(--warningAlt100));
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.Wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

.WaveScrolled {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.4s linear, transform 0.4s linear;
}

.GreetingContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.4s linear, opacity 0.4s linear;
}

.GreetingContainerScrolled {
  transform: translateY(-100%);
  opacity: 0;
}

.GreetingTitle {
  color: var(--colors-black);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.GreetingSubtitle {
  color: var(--colors-gray-600);
  font-size: 16px;
  line-height: 24px;
}
