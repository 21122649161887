.TotalDonationContainer {
  border-radius: var(--sizes-4);
  margin-bottom: var(--sizes-4);
  box-shadow: 0px 2px 16px 0px rgba(13, 13, 13, 0.08);
  background-color: var(--colors-white);

  & > div {
    box-sizing: border-box;
    padding-left: var(--sizes-6);
    padding-right: var(--sizes-6);

    &:nth-of-type(2) {
      padding-top: var(--sizes-6);
    }
  }
}
