.Wrapper {
  padding-block: 24px;
  background: radial-gradient(43.49% 51.92% at 88.61% 88.8%, rgba(var(--primary), 0.1) 0%, rgba(var(--primary), 0) 100%),
    radial-gradient(87.11% 94.04% at 83.75% 31.88%, rgba(var(--primary), 0.1) 0%, rgba(var(--primary), 0) 100%), #fff;
  border-bottom: 1px solid rgba(var(--gray90));
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.FlexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NoMatching {
  border-radius: 1rem;
  border-bottom: none;
}
