.DashboardCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 1em;
  background-color: #fff;
  box-shadow: 0px 2px 16px 0px rgba(var(--black), 0.08);
  overflow: hidden;

  [data-testid='upcoming-tab'] {
    height: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
    height: 100%;
    padding: 1rem 1.5rem;
  }
}

.FullHeight {
  height: 100%;

  @media (min-width: 1200px) {
    height: 340px;
  }
}

.GradientBackground {
  & > div {
    background: radial-gradient(77.03% 77.03% at 107.39% -13.37%, rgba(var(--primary400), 0.1) 0%, #fff 100%);
  }
}

.TitleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.TitleWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem 0 0;
}

.TitleIconWrapper {
  background-color: var(--colors-primary-100);
  color: var(--colors-primary-400);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SeeMoreBtnWrapper {
  display: inline-block;
  justify-self: flex-end;
  align-items: center;
}
