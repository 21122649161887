.Wrapper {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: rgba(var(--white));
  box-shadow: 0px 2px 16px 0px rgba(var(--black), 0.08);
}

.Link {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
}

.CoverImg {
  width: 100%;
  height: 168px;
  padding: 1rem;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: center;
}

.Title {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.Description {
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5rem;
  color: rgba(var(--gray600));
}

.ActionsWrapper {
  margin-top: auto;
}
