.PageWrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1050px) {
    flex-direction: column-reverse;
  }
}

.PageContentWrapper {
  max-width: 60%;
  flex: 6;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 1050px) {
    flex: 1;
    max-width: 100%;
  }

  @media (max-height: 775px) {
    bottom: 24px;
    top: unset;
  }
}
