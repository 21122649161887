.Wrapper {
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
  border-radius: 0.5rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  box-shadow: 0px 2px 16px 0px #0D0D0D14;
  background-color: rgba(var(--white));

  p {
    font-size: 0.875rem;
  }
}

.DetailsContainer {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;

  & > div {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }

  & p {
    color: rgba(var(--gray600))
  }

  svg {
    min-width: 1.5rem;
  }
}