.Wrapper {
  display: flex;
  flex-flow: row;

  & > div {
    &:first-of-type {
      width: 6.5rem;
      min-width: 6.5rem;
      padding: 0.5rem 0;
    }

    &:last-of-type {
      width: 100%;
    }
  }
}

.ContentWrapper {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
    display: block;
    width: 0.25rem;
    background-color: var(--colors-primary-400);
    border-radius: 0.25rem;
  }

  a {
    display: flex;
    flex-flow: column;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;

    &:hover {
      background-color: var(--colors-gray-70);
    }

    @media (min-width: 1024px) {
      span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .Attendees {
    color: var(--colors-gray-600) !important;
    font-weight: 500;
  }
}

.DateWrapper {
  display: flex;
  flex-flow: column;
  line-height: 1rem;

  span {
    &:first-of-type {
      color: var(--colors-gray-600);
    }

    &:last-of-type {
      font-weight: 500;
    }
  }
}
