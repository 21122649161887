.CenteredFlexBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--gray70));

  @media (max-width: 768px) {
    background-color: rgba(var(--white));
  }
}

.Card {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 456px;
  width: 100%;
  padding: 16px 56px 32px;
  border-radius: 0.5rem;
  text-align: left;

  img {
    margin: 30px 0;
    max-height: 28px;
    height: 200%;
    width: auto;
  }

  @media (max-width: 768px) {
    padding: 2.5rem 1.5rem;
  }
}

.Form {
  & > div {
    margin-bottom: 1rem;
  }
}

.ErrorMessage {
  text-align: center;
  color: rgba(var(--alert400)) !important;
}

.Title {
  margin: 12px 0 36px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    width: 100%;
    line-height: 45px;
  }
}

.Description {
  margin: 0 0 26px;
  text-align: center;
  font-size: 0.875rem;

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
  }
}

.ContentWrapper {
  width: 100%;

  button {
    width: 100%;
    margin: 40px 0;
  }
}

.LinksWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  a {
    color: rgba(var(--primary));
    transition: color 250ms;
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: 1px solid;
    border-color: rgba(var(--primary));

    &:hover {
      color: rgba(var(--primary600));
      border-color: rgba(var(--primary600));
    }

    &:first-of-type {
      color: rgba(var(--gray600));
      border-color: rgba(var(--gray600));
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }

  &.LinksWrapperCentered {
    justify-content: center;

    a {
      &:first-of-type {
        color: rgba(var(--primary));
        border-color: rgba(var(--primary));
      }
    }
  }
}
