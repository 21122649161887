.ActionsWrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  gap: 1rem;
}

.ButtonsWrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  gap: 1rem;

  & > button:not(:first-of-type){
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -1rem;
      width: 1px;
      height: 1.5rem;
      margin-left: 0.5rem;
      background-color: rgba(var(--gray20));
    }
  }
}

.MenuBtn {
  position: relative;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: 0;
  font-weight: 500;
  font-size: 14px;
  white-space: normal;
  background: transparent;
  color: rgba(var(--primary400));
  text-decoration-color: rgba(var(--primary400));
  text-decoration-style: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.25rem;

  @media (min-width: 769px) {
    &::after {
      content: '';
      position: absolute;
      left: -1rem;
      width: 1px;
      height: 1.5rem;
      margin-left: 0.5rem;
      background-color: rgba(var(--gray20));
    }
  }

  &:hover {
    color: rgba(var(--primary600));
    text-decoration-color: rgba(var(--primary600));
  }

  &:focus {
    text-decoration-color: rgba(var(--primary600));

    &::before {
      content: '';
      position: absolute;
      left: -0.25rem;
      right: -0.25rem;
      bottom: -0.25rem;
      top: -0.25rem;
      border-radius: 0.25rem; 
      box-shadow: 0 0 0 1px rgba(var(--primary600));
    }
  }

  &:active {
    color: rgba(var(--primary600));

    &::before {
      opacity: 0;
    }
  }
}