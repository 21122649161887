.Card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 16px 0px #0D0D0D14;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.CardContent {
  width: 100%;
  margin-right: 0.5rem;
}

.FlexBox {
  width: 100%;
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  gap: 1rem;
}

.TopWrapper {
  width: 100%;
  display: flex; 
  flex-direction: row; 
  align-items: flex-start; 
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.25rem;
}