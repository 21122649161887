.ActionSectionsWrapper {
  display: flex;
  flex-flow: column;
  gap: 2rem; 
}

.SectionTitle {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.SectionWrapper {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.SectionTilesContentWrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 1rem;

  & > div {
    width: 100%;

    @media(min-width: 768px) {
      width: calc(50% - 0.5rem) !important;
    }
  }
}