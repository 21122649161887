.PageContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 60%;
  flex: 6;
  padding-bottom: 4rem;

  @media (max-width: 1050px) {
    flex: 1;
    max-width: 100%;
  }
}

.Heading {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.SearchResultsWrapper {
  display: flex;
  flex: 6;
  padding-top: 1.5rem;

  @media (max-width: 900px) {
    flex: 1;
    flex-flow: column;
  }
}
