.Wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;

  & > div {
    &:first-of-type {
      width: 5rem;
      min-width: 5rem;
      padding: 0.5rem 0;
      text-align: right;

      @media (min-width: 480px) {
        width: 7.5rem;
        min-width: 7.5rem;
      }
    }

    &:last-of-type {
      width: 100%;
    }
  }
}

.ContentWrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0.5rem;
    width: 0.25rem;
    height: 1.25rem;
    background-color: var(--colors-primary-400);
    border-radius: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  a,
  .OrgButton {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
    outline: none;
    align-items: center;
    min-height: 2rem;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: var(--colors-gray-70);
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  @media (min-width: 1024px) {
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.DateWrapper {
  display: flex;
  line-height: 1rem;

  span {
    text-align: left;
    &:first-of-type {
      color: var(--colors-gray-600);
    }

    &:last-of-type {
      font-weight: 500;
    }
  }
}

.OrgText span {
  color: var(--colors-black) !important;
  font-weight: 500;
}
